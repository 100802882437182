import { Component } from '@angular/core';

import { environment } from '../environments/environment';
import { MessageService } from './message.service';
import { ProfileService } from './profile.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  env = environment;

  constructor(private messageService: MessageService,
              private profileService: ProfileService) { }

  onLogout(): void {
    this.messageService.set(`Logging you out ${this.profileService.getFirstName()}. Bye!`);
    this.profileService.logout();
  }
}
