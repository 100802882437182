import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AppListComponent } from './app-list/app-list.component';
import { AppDetailComponent } from './app-detail/app-detail.component';
import { AppUploadComponent } from './app-upload/app-upload.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { JobListComponent } from './job-list/job-list.component';
import { JobDetailComponent } from './job-detail/job-detail.component';
import { ProcDefComponent } from './proc-def-list/proc-def-list.component';
import { ProcDefDetailComponent } from './proc-def-detail/proc-def-detail.component';
import { ProcInstListComponent } from './proc-inst-list/proc-inst-list.component';
import { ProcInstDetailComponent } from './proc-inst-detail/proc-inst-detail.component';
import { ProcStarterComponent } from './proc-starter/proc-starter.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'dashboard'
  }, {
    path: 'apps',
    component: AppListComponent,
  }, {
    path: 'apps/app-detail/:id',
    component: AppDetailComponent
  }, {
    path: 'app-upload',
    component: AppUploadComponent
  }, {
    path: 'dashboard',
    component: DashboardComponent
  }, {
    path: 'jobs',
    component: JobListComponent
  }, {
    path: 'jobs/job-detail/:id',
    component: JobDetailComponent
  }, {
    path: 'proc-defs',
    component: ProcDefComponent
  }, {
    path: 'proc-defs/proc-def-detail/:id',
    component: ProcDefDetailComponent
  }, {
    path: 'proc-defs/proc-starter/:id',
    component: ProcStarterComponent
  }, {
    path: 'proc-insts',
    component: ProcInstListComponent
  }, {
    path: 'proc-insts/proc-inst-detail/:id',
    component: ProcInstDetailComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
