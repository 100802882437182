<table class="govuk-table">
  <!--caption class="govuk-heading-l govuk-table__caption">Process Definitions</caption-->
  <thead class="govuk-table__head">
    <tr class="govuk-table__row">
      <th scope="col" class="govuk-table__header"></th>
      <th scope="col" class="govuk-table__header">Name</th>
      <!--th scope="col" class="govuk-table__header">Key</th-->
      <th scope="col" class="govuk-table__header">Description</th>
      <!--th scope="col" class="govuk-table__header">Version</th-->
      <!--th scope="col" class="govuk-table__header">Category</th-->
      <th scope="col" class="govuk-table__header">&nbsp;</th>
    </tr>
  </thead>
  <tbody class="govuk-table__body">
    <tr *ngFor="let procDef of procDefs; let i = index" class="govuk-table__row"
        [class.selected]="procDef === selectedProcDef">
      <td scope="row" class="govuk-table__header"><a [routerLink]="['/proc-defs', procDef.id]">{{i+1}}</a></td>
      <!--td scope="row" class="govuk-table__header">{{procDef.id}}</td-->
      <td class="govuk-table__cell">{{procDef.name}}</td>
      <!--td class="govuk-table__cell">{{procDef.key}}</td-->
      <td class="govuk-table__cell">{{procDef.description}}</td>
      <!--td class="govuk-table__cell">{{procDef.version}}</td-->
      <!--td class="govuk-table__cell">{{procDef.category}}</td-->
      <td class="govuk-table__cell">
        <button class="govuk-button" data-module="govuk-button" [routerLink]="['/proc-defs/proc-def-detail', procDef.id]">
          View details
        </button>
      </td>
    </tr>
  </tbody>
</table>
<app-pagination><app-pagination>
