import { Component, OnInit } from '@angular/core';

import { MessageService } from '../message.service';
import { ProfileService } from '../profile.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  constructor(private messageService: MessageService,
              private profileService: ProfileService) { }

  ngOnInit(): void {
    this.messageService.set(`Welcome back ${this.profileService.getFirstName()}!`);
  }

}
