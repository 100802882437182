
<div>
  <dl class="govuk-summary-list">
    <div class="govuk-summary-list__row">
      <dt class="govuk-summary-list__key">Id</dt>
      <dd class="govuk-summary-list__value">{{job.id}}</dd>
    </div>
    <div *ngIf="job.processDefinitionId" class="govuk-summary-list__row">
      <dt class="govuk-summary-list__key"> Process definition </dt>
      <dd class="govuk-summary-list__value"> {{job.processDefinitionId}} </dd>
    </div>
    <div *ngIf="job.processInstanceId" class="govuk-summary-list__row">
      <dt class="govuk-summary-list__key">Process instance</dt>
      <dd class="govuk-summary-list__value"> {{job.processInstanceId}} </dd>
    </div>
    <div *ngIf="job.executionId" class="govuk-summary-list__row">
      <dt class="govuk-summary-list__key">Execution id</dt>
      <dd class="govuk-summary-list__value"> {{job.executionId}} </dd>
    </div>
    <div *ngIf="job.retries" class="govuk-summary-list__row">
      <dt class="govuk-summary-list__key">Retries</dt>
      <dd class="govuk-summary-list__value"> {{job.retries}} </dd>
    </div>
    <div *ngIf="job.exceptionMessage" class="govuk-summary-list__row">
      <dt class="govuk-summary-list__key">Exception</dt>
      <dd class="govuk-summary-list__value"> {{job.exceptionMessage}} </dd>
    </div>
    <div *ngIf="job.stackTrace" class="govuk-summary-list__row">
      <dt class="govuk-summary-list__key">Stack trace</dt>
      <dd class="govuk-summary-list__value"><pre> {{job.stackTrace}} </pre></dd>
    </div>
  </dl>

  <button class="govuk-button" data-module="govuk-button" [routerLink]="['/jobs']">
    Back to list
  </button>
</div>
