import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import { environment } from '../../environments/environment';

import { MessageService } from '../message.service';
import { HistoryService } from '../history.service';
import { ProcessInstance } from '../process-instance';
import { RuntimeService } from '../runtime.service';
import { HistoricActivityInstance } from '../historic-activity-instance';
import { Variable } from '../variable';

@Component({
  selector: 'app-proc-inst-detail',
  templateUrl: './proc-inst-detail.component.html',
  styleUrls: ['./proc-inst-detail.component.scss']
})
export class ProcInstDetailComponent implements OnInit {

  active: boolean;
  dataSize: number;
  procInstId: string;
  procInst: ProcessInstance;
  activities: HistoricActivityInstance[];
  childInsts: ProcessInstance[];
  selectedActivity: HistoricActivityInstance;
  selectedChildInst: ProcessInstance;
  dataObjs: [];
  variables: Variable[];
  selectedVar: Variable;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private historyService: HistoryService,
              private runtimeService: RuntimeService,
              private messageService: MessageService) { }

  ngOnInit(): void {
    this.messageService.set(`Viewing process instance...`);
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.procInstId = params.get('id');
      this.route.queryParamMap
        .subscribe((params) => {
          // tslint:disable-next-line: radix
          this.dataSize = params.get('dataSize') === null ? environment.dataSize : parseInt(params.get('dataSize'));
          this.active = params.get('active') === null ? false : params.get('active') === 'active';
          this.messageService.set(`Viewing process instance ${this.procInstId}...`);
          if (this.active) {
            this.getProcessInstance(this.dataSize);
          } else {
            this.getHistoricProcessInstance(this.dataSize);
          }
        }
      );
    })
  }

  getProcessInstance(dataSize: number): void {
    this.runtimeService.getProcessInstance(this.procInstId)
        .subscribe(procInst => {
          this.procInst = procInst;
          this.runtimeService.getProcessInstanceVariables(this.procInstId, dataSize)
              .subscribe(variables => this.procInst.variables = variables);
        });
    this.historyService.getHistoricActivityInstances(this.procInstId)
        .subscribe(activities => this.activities = activities);
    const query = { superProcessInstanceId: this.procInstId };
    this.runtimeService.queryProcessInstances(query)
        .subscribe(insts => this.childInsts = insts);
}

  getHistoricProcessInstance(dataSize: number): void {
    this.historyService.getHistoricProcessInstance(this.procInstId)
        .subscribe(procInst => {
          this.procInst = procInst;
          this.historyService.getHistoricProcessInstanceVariables(this.procInstId, dataSize)
              .subscribe(variables => this.procInst.variables = variables);
        });
    this.historyService.getHistoricActivityInstances(this.procInstId)
        .subscribe(activities => this.activities = activities);
    const query = { superProcessInstanceId: this.procInstId };
    this.runtimeService.queryProcessInstances(query)
        .subscribe(insts => this.childInsts = insts);
  }

  onDelete(): void {
    this.messageService.set(`Deleting process definition...`);

    if (this.procInst.endTime === null) {
      this.runtimeService
        .deleteProcessInstance(this.procInstId)
        .subscribe(app => {
          this.router.navigate(['/proc-insts']);
        });
    } else {
      this.historyService
        .deleteHistoricProcessInstance(this.procInstId)
        .subscribe(app => {
          this.router.navigate(['/proc-insts']);
        });
    }
  }
}
