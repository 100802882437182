import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';

import { MessageService } from './message.service';
import { PaginationService } from './pagination.service';
import { AppDefinition } from './app-definition';
import { AppDefinitionResponse } from './app-definition-response';
import { AppResource } from './app-resource';
import { DiscoveryService } from './discovery-service';
import { ProfileService } from './profile.service';
import { QueryMetaData } from './query-metadata';
import { AppDeployment } from './app-deployment';
import { RepoService } from './repo.service';
import { ProcessDefinition } from './process-definition';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor(
      private discoveryService: DiscoveryService,
      private profileService: ProfileService,
      private http: HttpClient,
      private messageService: MessageService,
      private paginationService: PaginationService,
      private repoService: RepoService) { }

  /** Log a message with the MessageService */
  private log(message: string) {
    this.messageService.set(`${message}`);
  }

  deleteAppDeployment(appDeploymentId: string) {
    return this.http.delete( this.discoveryService.getDeleteAppDeploymentUrl(appDeploymentId), {headers: this.getHttpHeaders()} )
      .pipe(
        catchError(this.handleError('deleteAppDeployment', undefined))
      );
  }

  getAppDefinitions(query: QueryMetaData): Observable<AppDefinition[]> {
    return this.http.get<AppDefinitionResponse>( this.discoveryService.getAppDefinitionsUrl(query), {headers: this.getHttpHeaders()} )
      .pipe(
        tap(x => this.log(`Found ${x.total} app${x.total > 1 ? 's' : ''}`)),
        tap(x => this.paginationService.paginate('/apps', { total: x.total, start: x.start, size: query.size} as QueryMetaData)),
        map(x => x.data), /* flowable response includes metadata, just want data */
        catchError(this.handleError<AppDefinition[]>('getAppDefinitions', []))
      );
  }

  getAppDefinition(appDefId: string): Observable<AppDefinition> {
    this.log(`Showing details of ${appDefId}`);
    return this.http.get<AppDefinition>( this.discoveryService.getAppDefinitionUrl(appDefId), {headers: this.getHttpHeaders()} )
      .pipe(
        tap(x => this.log(`Found app '${x.name}'`)),
        tap(x => {
          this.getAppResources(x.deploymentId)
              .subscribe(appResources => x.resources = appResources);
          this.repoService.getChildProcessDeployments(x.deploymentId)
              .subscribe(processDeployments => x.processDeployments = processDeployments);
        }),
        catchError(this.handleError<AppDefinition>('getAppDefinition', undefined))
      );
  }

  getAppResources(appDeploymentId: string): Observable<AppResource[]> {
    return this.http.get<AppResource[]>( this.discoveryService.getAppResourcesUrl(appDeploymentId), {headers: this.getHttpHeaders()} )
      .pipe(
        tap(x => {
          var repoService = this.repoService;
          x.forEach(function(resource) {
            if (resource.id.endsWith('.bpmn')) {
              repoService.getProcessDefinitions({ start: 0, size: 5 } as QueryMetaData, {resourceName:resource.id})
                .subscribe(procDefs => {
                  resource.contentIds = procDefs.map(function(d: ProcessDefinition) { return d.id; })
                });
            }
          })
        }),
        catchError(this.handleError<AppResource[]>('getAppResources', []))
      );
  }

  upload(formData): Observable<AppDeployment> {
    this.log(`Uploading new app`);
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Authorization', 'Basic ' + btoa(this.profileService.getUsername() + ':' + this.profileService.getToken()));
    return this.http.post<AppDeployment>( this.discoveryService.getAppDeploymentUrl(), formData, { headers } )
      .pipe(
        tap(x => this.log(`Deployed app '${x.id}'`)),
        catchError(this.handleError<AppDeployment>('upload', formData))
      );
  }

  private getHttpHeaders(): HttpHeaders {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', 'Basic ' + btoa(this.profileService.getUsername() + ':' + this.profileService.getToken()));
    return headers;
  }
  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
