import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';

import { MessageService } from '../message.service';
import { RepoService } from '../repo.service';
import { IdentityLink } from '../identity-link';

@Component({
  selector: 'app-proc-starter',
  templateUrl: './proc-starter.component.html',
  styleUrls: ['./proc-starter.component.scss']
})
export class ProcStarterComponent implements OnInit {

  procDefId: string;
  setStarterForm: FormGroup;

  constructor(private formBuilder: FormBuilder,
              private messageService: MessageService,
              private repoService: RepoService,
              private route: ActivatedRoute,
              private router: Router) {
    this.procDefId = this.route.snapshot.paramMap.get('id');
    this.setStarterForm = this.formBuilder.group({
      starter: ''
    });
  }

  ngOnInit(): void {
    this.messageService.clear();
  }

  onChange(event) {
    if (event.target.length > 0) {
      this.setStarterForm.get('starter').setValue(event.target.value);
    }
  }

  onSubmit(): void {
    this.messageService.set(`Setting starter...`);
    const starter = this.setStarterForm.get('starter').value;
    const payload: IdentityLink = {
      type: 'candidate'
    };
    starter.indexOf('@') > 0 ? payload.user = starter : payload.group = starter;
    this.repoService
      .setStarter(this.procDefId, payload)
      .subscribe(_ => this.router.navigate(['/proc-defs/proc-def-detail', this.procDefId]) );
  }

}
