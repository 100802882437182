import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';

import { MessageService } from './message.service';
import { Job } from './job';
import { JobResponse } from './job-response';
import { DiscoveryService } from './discovery-service';
import { PaginationService } from './pagination.service';
import { ProfileService } from './profile.service';
import { QueryMetaData } from './query-metadata';

@Injectable({
  providedIn: 'root'
})
export class ManagementService {

  constructor(
      private discoveryService: DiscoveryService,
      private paginationService: PaginationService,
      private profileService: ProfileService,
      private http: HttpClient,
      private messageService: MessageService) { }

  /** Log a ManagementService message with the MessageService */
  private log(message: string) {
    this.messageService.set(`${message}`);
  }

  getDeadletterJobs(query: QueryMetaData): Observable<Job[]> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', 'Basic ' + btoa(this.profileService.getUsername() + ':' + this.profileService.getToken()));
    return this.http.get<JobResponse>( this.discoveryService.getDeadletterJobsUrl(query), {headers: headers} )
      .pipe(
        tap(x => this.log(`Found ${x.total} error job${x.total != 1 ? 's' : ''} currently pending`)),
        tap(x => this.paginationService.paginate('/jobs', { total: x.total, start: x.start, size: query.size} as QueryMetaData)),
        map(x => x.data), /* flowable response includes metadata, just want data */
        catchError(this.handleError<Job[]>('getJobs', []))
      );
  }

  getDeadletterJob(jobId: string): Observable<Job> {
    this.log(`Showing details of ${jobId}`);
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', 'Basic ' + btoa(this.profileService.getUsername() + ':' + this.profileService.getToken()));
    return this.http.get<Job>( this.discoveryService.getDeadletterJobUrl(jobId), {headers: headers} )
      .pipe(
        tap(x => this.log(`Found job'${x.id}'`)),
        catchError(this.handleError<Job>('getDeadletterJob', undefined))
      );
  }

  getDeadletterJobStackTrace(jobId: string): Observable<string> {
    this.log(`Showing details of ${jobId}`);
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Accept', 'text/plain');
    headers = headers.append('Authorization', 'Basic ' + btoa(this.profileService.getUsername() + ':' + this.profileService.getToken()));
    return this.http.get( this.discoveryService.getDeadletterJobStackTraceUrl(jobId), {headers: headers, responseType: 'text'} )
      .pipe(
        tap(() => this.log(`Found stack trace`)),
        //catchError(this.handleError<string>('getDeadletterJob', undefined))
      );
  }

  getJobs(query: QueryMetaData): Observable<Job[]> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', 'Basic ' + btoa(this.profileService.getUsername() + ':' + this.profileService.getToken()));
    return this.http.get<JobResponse>( this.discoveryService.getJobsUrl(query), {headers: headers} )
      .pipe(
        tap(x => this.log(`Found ${x.total} job${x.total != 1 ? 's' : ''} currently pending`)),
        tap(x => this.paginationService.paginate('/jobs', { total: x.total, start: x.start, size: query.size} as QueryMetaData)),
        map(x => x.data), /* flowable response includes metadata, just want data */
        catchError(this.handleError<Job[]>('getJobs', []))
      );
  }

  getJob(jobId: string): Observable<Job> {
    this.log(`Showing details of ${jobId}`);
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', 'Basic ' + btoa(this.profileService.getUsername() + ':' + this.profileService.getToken()));
    return this.http.get<Job>( this.discoveryService.getJobUrl(jobId), {headers: headers} )
      .pipe(
        tap(x => this.log(`Found job'${x.id}'`)),
        catchError(this.handleError<Job>('getJob', undefined))
      );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
