import { Injectable } from '@angular/core';

import { KeycloakProfile } from 'keycloak-js';

import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  profile: KeycloakProfile;
  token: string;

  constructor() {
    this.profile = JSON.parse(localStorage['ang-profile']);
    this.token = JSON.parse(localStorage['ang-profile']).attributes.token;
  }

  set(profile: KeycloakProfile) {
    this.profile = profile;
  }

  getEmail(): string {
    return this.profile.email;
  }
  getFirstName(): string {
    return this.profile.firstName;
  }
  getLastName(): string {
    return this.profile.lastName;
  }
  getToken(): string {
    return this.token;
  }
  getUsername(): string {
    return this.profile.username;
  }
  logout() {
    const redirect = window.location.origin;
    // tslint:disable-next-line: max-line-length
    const logoutUrl = `${environment.authServer.url}/realms/${environment.authServer.realm}/protocol/openid-connect/logout?redirect_uri=${redirect}`;
    window.location.href = logoutUrl;
  }
}
