import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import { MessageService } from '../message.service';
import { ManagementService } from '../management.service';
import { Job } from '../job';

@Component({
  selector: 'app-job-detail',
  templateUrl: './job-detail.component.html',
  styleUrls: ['./job-detail.component.scss']
})
export class JobDetailComponent implements OnInit {

  jobId: string;
  job: Job;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private managementService: ManagementService,
              private messageService: MessageService) { }

  ngOnInit(): void {
    this.jobId = this.route.snapshot.paramMap.get('id');
    this.messageService.set(`Viewing deadletter job ${this.jobId}...`);
    this.managementService.getDeadletterJob(this.jobId)
        .subscribe(job => {
          this.job = job;
          this.managementService.getDeadletterJobStackTrace(this.jobId)
            .subscribe(stacktrace => this.job.stackTrace = stacktrace );
        });
  }

}
