<div class="govuk-form-group">
  <fieldset class="govuk-fieldset" aria-describedby="active-hint">
    <div class="govuk-radios govuk-radios--inline">
      <div class="govuk-radios__item">
        <input class="govuk-radios__input" id="active" name="active" type="radio" value="active"
               [checked]="active" (click)="getProcessInstances(query)">
        <label class="govuk-label govuk-radios__label" for="active"> Active </label>
      </div>
      <div class="govuk-radios__item">
        <input class="govuk-radios__input" id="active-2" name="active" type="radio" value="complete"
               [checked]="!active" (click)="getHistoricProcessInstances(query)">
        <label class="govuk-label govuk-radios__label" for="active-2"> Complete </label>
      </div>
    </div>
  </fieldset>
</div>

<table class="govuk-table">
  <!--caption class="govuk-heading-l govuk-table__caption">Process Instinitions</caption-->
  <thead class="govuk-table__head">
    <tr class="govuk-table__row">
      <th scope="col" class="govuk-table__header"></th>
      <th scope="col" class="govuk-table__header">Business key</th>
      <th scope="col" class="govuk-table__header">Name</th>
      <th scope="col" class="govuk-table__header">Definition</th>
      <th scope="col" class="govuk-table__header">Description</th>
      <th scope="col" class="govuk-table__header govuk-width-datetime">Created</th>
      <th scope="col" class="govuk-table__header govuk-width-datetime">Completed</th>
      <th scope="col" class="govuk-table__header"></th>
    </tr>
  </thead>
  <tbody class="govuk-table__body">
    <tr *ngFor="let procInst of procInsts; let i = index" class="govuk-table__row"
        [class.selected]="procInst === selectedProcInst">
      <td scope="row" class="govuk-table__header"><a [routerLink]="['/proc-insts/proc-inst-detail', procInst.id]">{{i+1}}</a></td>
      <!--td scope="row" class="govuk-table__header">{{procInst.id}}</td-->
      <td class="govuk-table__cell" title="{{procInst.businessKey}}">{{procInst.businessKey == null ? '-' : procInst.businessKey}}</td>
      <td class="govuk-table__cell" title="{{procInst.name}}">{{procInst.name == null ? '-' : procInst.name}}</td>
      <td class="govuk-table__cell" title="{{procInst.processDefinitionName}}">
        {{procInst.processDefinitionName == null ? procInst.processDefinitionId : procInst.processDefinitionName | slice:0:20}}
        {{procInst.processDefinitionName.length > 20 ? '...' : ''}}
      </td>
      <td class="govuk-table__cell" title="{{procInst.processDefinitionDescription}}">
        {{procInst.processDefinitionDescription == null ? '-' : procInst.processDefinitionDescription | slice:0:50}}
        {{procInst.processDefinitionDescription.length > 50 ? '...' : ''}}
      </td>
      <td class="govuk-table__cell">{{procInst.startTime | date:"dd MMM yy HH:mm"}}</td>
      <td class="govuk-table__cell">{{procInst.endTime | date:"dd MMM yy HH:mm"}}</td>
      <td class="govuk-table__cell">
        <button class="govuk-button" data-module="govuk-button" [routerLink]="['/proc-insts/proc-inst-detail', procInst.id]" [queryParams]="[active]">
          View details
        </button>
      </td>
    </tr>
  </tbody>
</table>
<app-pagination [active]="active"><app-pagination>
