<form [formGroup]="uploadForm">
<fieldset class="govuk-fieldset govuk-!-margin-top-2">
  <legend class="govuk-fieldset__legend govuk-fieldset__legend--l">
    <h1 class="govuk-fieldset__heading">
      Specify details of the new app
    </h1>
  </legend>

  <div class="govuk-form-group">
    <label class="govuk-label" for="file-upload-1">
      Upload an app file
    </label>
    <input class="govuk-file-upload" id="file" name="file" required type="file"
           formControlName="file" (change)="onFileSelect($event)">

  </div>

  <button class="govuk-button" data-module="govuk-button"
          (click)="onSubmit()">
    Save and continue
  </button>
</fieldset>
</form>
