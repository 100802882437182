<div *ngIf="procDef">
  <dl class="govuk-summary-list">
    <div class="govuk-summary-list__row">
      <dt class="govuk-summary-list__key">Id</dt>
      <dd class="govuk-summary-list__value">{{procDef.id}}</dd>
    </div>
    <div *ngIf="procDef.name" class="govuk-summary-list__row">
      <dt class="govuk-summary-list__key"> Name </dt>
      <dd class="govuk-summary-list__value"> {{procDef.name}} </dd>
    </div>
    <div *ngIf="procDef.version" class="govuk-summary-list__row">
      <dt class="govuk-summary-list__key">Version</dt>
      <dd class="govuk-summary-list__value"> {{procDef.version}} </dd>
    </div>
    <div *ngIf="procDef.description" class="govuk-summary-list__row">
      <dt class="govuk-summary-list__key">Description </dt>
      <dd class="govuk-summary-list__value"> {{procDef.description}} </dd>
    </div>
    <div *ngIf="procDef.category" class="govuk-summary-list__row">
      <dt class="govuk-summary-list__key">Category</dt>
      <dd class="govuk-summary-list__value"> {{procDef.category}} </dd>
    </div>
    <div class="govuk-summary-list__row">
      <dt class="govuk-summary-list__key">Suspended?</dt>
      <dd class="govuk-summary-list__value"> {{procDef.suspended}} </dd>
    </div>
    <div class="govuk-summary-list__row">
      <dt class="govuk-summary-list__key">Start form defined?</dt>
      <dd class="govuk-summary-list__value"> {{procDef.startFormDefined}} </dd>
    </div>
    <div class="govuk-summary-list__row">
      <dt class="govuk-summary-list__key">Startable by</dt>
      <dd class="govuk-summary-list__value">
      <span *ngFor="let starter of procDef.starters; let i = index" class="govuk-table__row">
        {{starter.group == undefined ? starter.user : starter.group}};
      </span>
      <button class="govuk-button govuk-button-secondary" data-module="govuk-button" [routerLink]="['/proc-defs/proc-starter', procDef.id]">Add</button>
    </div>
    <div class="govuk-summary-list__row">
      <dt class="govuk-summary-list__key">Deployment id</dt>
      <dd class="govuk-summary-list__value"> {{procDef.deploymentId}} </dd>
    </div>
    <div *ngIf="procDef.resourceContent" class="govuk-summary-list__row">
      <dt class="govuk-summary-list__key">Diagram</dt>
      <dd *ngIf="!procDef.graphicalNotationDefined" class="govuk-summary-list__value"> Not available</dd>
    </div>
    <div *ngIf="procDef.graphicalNotationDefined" class="govuk-summary-list__row">
      <dd class="" [innerHTML]="html"></dd>
    </div>
    <div *ngIf="procDef.resourceContent" class="govuk-summary-list__row" style="display:none">
      <dt class="govuk-summary-list__key">XML</dt>
      <dd class="govuk-summary-list__value">
        <span class="hidden" style="display:none"> {{procDef.resourceContent.content}} </span>
      </dd>
    </div>
  </dl>

  <div class="govuk-button-group">
    <button class="govuk-button" data-module="govuk-button" [routerLink]="['/proc-defs']">
      Back to list
    </button>
  </div>
</div>
