// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  authServer: {
    url: 'https://auth.knowprocess.com/auth',
    realm: 'knowprocess',
    clientId: 'admin-ng'
  },
  server: {
    // url: 'http://localhost:7080/process-api'
    url: 'https://flowable.knowprocess.com/flowable-rest',
    appApi: 'app-api',
    processApi: 'service'
  },
  contentLinks: {
    contact: 'https://knowprocess.com/contact-us/',
    copyright: undefined,
    copyrightText: '© 2021 KnowProcess Ltd',
    favicon: 'https://knowprocess.com/images/icon/knowprocess-icon-16x16.png',
    feedback: 'https://knowprocess.com/contact-us/',
    home: '/',
    help: undefined,
    licence: 'http://www.apache.org/licenses/LICENSE-2.0.html',
    licenceText: 'Apache License v2.0',
    logo: 'https://knowprocess.com/images/knowprocess-logo.png',
    logoText: undefined,
    privacy: 'https://knowprocess.com/privacy-notice/',
    terms: undefined,
  },
  pageSize: 5,
  dataSize: 50
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
