import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { MessageService } from '../message.service';
import { ProcessDefinition } from '../process-definition';
import { ProcessInstance } from '../process-instance';
import { QueryMetaData} from '../query-metadata';
import { RepoService } from '../repo.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-proc-def',
  templateUrl: './proc-def-list.component.html',
  styleUrls: ['./proc-def-list.component.scss']
})
export class ProcDefComponent implements OnInit {

  procDefs: ProcessDefinition[];
  selectedProcDef: ProcessDefinition;
  procInst: ProcessInstance;
  queryObj: QueryMetaData;

  constructor(private route: ActivatedRoute,
              private repoService: RepoService,
              private messageService: MessageService) { }

  ngOnInit(): void {
    this.route.queryParamMap
      .subscribe((params) => {
        // tslint:disable-next-line: radix
        const start = params.get('start') === null ? 0 : parseInt(params.get('start'));
        // tslint:disable-next-line: radix
        const size = params.get('size') === null ? environment.pageSize : parseInt(params.get('size'));
        this.queryObj = { start, size } as QueryMetaData;
        this.getProcessDefinitions(this.queryObj);
      }
    );
  }

  getProcessDefinitions(query: QueryMetaData): void {
    this.repoService.getProcessDefinitions(query)
        .subscribe(procDefs => this.procDefs = procDefs);
  }

  /** Start process instance without any inputs (no start form) */
  onSelect(procDef: ProcessDefinition): void {
    this.selectedProcDef = procDef;
    this.messageService.set(`Process definition selected id=${procDef.id}`);
    //this.repoService
    //  .startInstance(procDef.id)
    //  .subscribe(procInst => this.procInst = procInst);
  }
}
