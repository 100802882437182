import { Component, OnInit } from '@angular/core';
import { KeyValueChanges, KeyValueDiffer, KeyValueDiffers } from '@angular/core';

import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

import { MessageService } from '../message.service';
import { ProcessDefinition } from '../process-definition';
import { ProcessInstance } from '../process-instance';
import { RepoService } from '../repo.service';

@Component({
  selector: 'app-proc-def-detail',
  templateUrl: './proc-def-detail.component.html',
  styleUrls: ['./proc-def-detail.component.scss']
})
export class ProcDefDetailComponent implements OnInit {

  html: SafeHtml;
  procDefId: string;
  procDef: ProcessDefinition;
  procDefDiffer: KeyValueDiffer<string, any>;

  constructor(private route: ActivatedRoute,
              private repoService: RepoService,
              private messageService: MessageService,
              private router: Router,
              private sanitizer: DomSanitizer,
              private differs: KeyValueDiffers) { }

  ngOnInit(): void {
    this.messageService.set(`Viewing process definition...`);
    this.procDefId = this.route.snapshot.paramMap.get('id');
    this.messageService.set(`Viewing process definition ${this.procDefId}...`);
    this.repoService.getProcessDefinition(this.procDefId)
        .subscribe(procDef => {
          this.procDef = procDef;
          // proc def has to be read in several chunks so use Differ to detect changes
          this.procDefDiffer = this.differs.find(this.procDef).create();
        });
  }

  procDefResourceChanged(changes: KeyValueChanges<string, any>) {
    this.html = this.sanitizer.bypassSecurityTrustHtml(this.procDef.resourceContent.diagram);
  }

  ngDoCheck(): void {
    if (this.procDef && this.procDef.resourceContent && this.procDef.resourceContent.diagram) {
      const changes = this.procDefDiffer.diff(this.procDef.resourceContent);
      if (changes) {
        this.procDefResourceChanged(changes);
      }
    }
  }
}
