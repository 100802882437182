import { Injectable } from '@angular/core';

import { environment } from '../environments/environment';

import { ProfileService } from './profile.service';
import { QueryMetaData } from './query-metadata';

@Injectable({
  providedIn: 'root',
})
export class DiscoveryService {
  constructor(private profileService: ProfileService) { }

  private queryProcessInstancesUrl = `${environment.server.url}/${environment.server.processApi}/query/process-instances`;

  getDeleteAppDeploymentUrl(appDeploymentId: string): string {
    return `${environment.server.url}/${environment.server.appApi}/app-repository/deployments/${appDeploymentId}`;
  }

  getDeleteProcessDeploymentUrl(processDeploymentId: string, cascade: boolean): string {
    return `${environment.server.url}/${environment.server.processApi}/repository/deployments/${processDeploymentId}?cascade=${cascade}`;
  }

  getAppDefinitionsUrl(query: QueryMetaData): string {
    // tslint:disable-next-line: max-line-length
    return `${environment.server.url}/${environment.server.appApi}/app-repository/app-definitions?latest=true&start=${query.start}&size=${query.size}`;
  }

  getAppDefinitionUrl(appDefId: string): string {
    return `${environment.server.url}/${environment.server.appApi}/app-repository/app-definitions/${appDefId}`;
  }

  getAppDeploymentUrl(): string {
    return `${environment.server.url}/${environment.server.appApi}/app-repository/deployments/`;
  }

  getAppResourcesUrl(appDefId: string): string {
    return `${environment.server.url}/${environment.server.appApi}/app-repository/deployments/${appDefId}/resources`;
  }

  getHistoricActivityInstancesUrl(procInstId: string): string {
    let dataSize = 50;
    return `${environment.server.url}/${environment.server.processApi}/history/historic-activity-instances?processInstanceId=${procInstId}&start=0&size=${dataSize}`;
  }

  getHistoricProcessInstancesUrl(query: QueryMetaData): string {
    // tslint:disable-next-line: max-line-length
    return `${environment.server.url}/${environment.server.processApi}/history/historic-process-instances?start=${query.start}&size=${query.size}&sort=startTime&order=desc`;
  }

  getHistoricProcessInstanceUrl(procInstId: string): string {
    return `${environment.server.url}/${environment.server.processApi}/history/historic-process-instances/${procInstId}`;
  }

  getHistoricProcessInstanceVarablesUrl(procInstId: string, dataSize: number): string {
    // tslint:disable-next-line: max-line-length
    return `${environment.server.url}/${environment.server.processApi}/history/historic-variable-instances/?size=${dataSize}&processInstanceId=${procInstId}`;
  }

  getJobsUrl(query: QueryMetaData): string {
    return `${environment.server.url}/${environment.server.processApi}/management/jobs?start=${query.start}&size=${query.size}`;
  }

  getJobUrl(jobId: string): string {
    return `${environment.server.url}/${environment.server.processApi}/management/jobs/${jobId}`;
  }

  getDeadletterJobsUrl(query: QueryMetaData): string {
    return `${environment.server.url}/${environment.server.processApi}/management/deadletter-jobs?start=${query.start}&size=${query.size}&sort=dueDate&order=desc`;
  }

  getDeadletterJobUrl(jobId: string): string {
    return `${environment.server.url}/${environment.server.processApi}/management/deadletter-jobs/${jobId}`;
  }

  getDeadletterJobStackTraceUrl(jobId: string): string {
    return `${environment.server.url}/${environment.server.processApi}/management/deadletter-jobs/${jobId}/exception-stacktrace`;
  }

  getProcessDefinitionsUrl(queryMeta: QueryMetaData, query: object): string {
    // tslint:disable-next-line: max-line-length
    let url = `${environment.server.url}/${environment.server.processApi}/repository/process-definitions?latest=true&start=${queryMeta.start}&size=${queryMeta.size}`;
    Object.keys(query).forEach(function(key) { url+=('&'+key+'='+query[key]); });
    return url;
  }

  getProcessDefinitionUrl(procDefId: string): string {
    return `${environment.server.url}/${environment.server.processApi}/repository/process-definitions/${procDefId}`;
  }

  getProcessDeploymentsUrl(): string {
    return `${environment.server.url}/${environment.server.processApi}/repository/deployments/`;
  }

  getProcessInstancesUrl(query: QueryMetaData): string {
    return `${environment.server.url}/${environment.server.processApi}/runtime/process-instances?start=${query.start}&size=${query.size}&sort=startTime&order=desc`;
  }

  getProcessInstanceUrl(procInstId: string): string {
    return `${environment.server.url}/${environment.server.processApi}/runtime/process-instances/${procInstId}`;
  }

  getProcessInstanceVarablesUrl(procInstId: string, dataSize: number): string {
    return `${environment.server.url}/${environment.server.processApi}/runtime/process-instances/${procInstId}/variables?size=${dataSize}`;
  }

  getQueryProcessInstancesUrl(): string {
    return this.queryProcessInstancesUrl;
  }

  getProcessStartersUrl(procDefId: string): string {
    return `${environment.server.url}/${environment.server.processApi}/repository/process-definitions/${procDefId}/identitylinks`;
  }

  getToken(): string {
    return this.profileService.getToken();
  }

  getUsername(): string {
    return this.profileService.getUsername();
  }

}
