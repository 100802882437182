import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { MessageService } from '../message.service';
import { AppDefinition } from '../app-definition';
import { QueryMetaData} from '../query-metadata';
import { AppService } from '../app.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-app-list',
  templateUrl: './app-list.component.html',
  styleUrls: ['./app-list.component.scss']
})
export class AppListComponent implements OnInit {

  apps: AppDefinition[];
  selectedApp: AppDefinition;
  queryObj: QueryMetaData;

  constructor(private route: ActivatedRoute,
              private appService: AppService,
              private messageService: MessageService) { }

  ngOnInit(): void {
    this.route.queryParamMap
      .subscribe((params) => {
        // tslint:disable-next-line: radix
        const start = params.get('start') === null ? 0 : parseInt(params.get('start'));
        // tslint:disable-next-line: radix
        const size = params.get('size') === null ? environment.pageSize : parseInt(params.get('size'));
        this.queryObj = { start, size } as QueryMetaData;
        this.getAppDefinitions(this.queryObj);
      }
    );
  }

  getAppDefinitions(query: QueryMetaData): void {
    this.appService.getAppDefinitions(query)
        .subscribe(apps => this.apps = apps);
  }

  /** Start app instance without any inputs (no start form) */
  onSelect(app: AppDefinition): void {
    this.selectedApp = app;
    this.messageService.set(`App selected id=${app.id}`);
  }
}
