<div *ngIf="app">
  <dl class="govuk-summary-list">
    <div class="govuk-summary-list__row">
      <dt class="govuk-summary-list__key">Id</dt>
      <dd class="govuk-summary-list__value">{{app.id}}</dd>
    </div>
    <div *ngIf="app.name" class="govuk-summary-list__row">
      <dt class="govuk-summary-list__key"> Name </dt>
      <dd class="govuk-summary-list__value"> {{app.name}} </dd>
    </div>
    <div *ngIf="app.version" class="govuk-summary-list__row">
      <dt class="govuk-summary-list__key">Version</dt>
      <dd class="govuk-summary-list__value"> {{app.version}} </dd>
    </div>
    <div *ngIf="app.description" class="govuk-summary-list__row">
      <dt class="govuk-summary-list__key">Description </dt>
      <dd class="govuk-summary-list__value"> {{app.description}} </dd>
    </div>
    <div *ngIf="app.category" class="govuk-summary-list__row">
      <dt class="govuk-summary-list__key">Category</dt>
      <dd class="govuk-summary-list__value"> {{app.category}} </dd>
    </div>
    <div *ngIf="app.deploymentId" class="govuk-summary-list__row">
      <dt class="govuk-summary-list__key">Deployment id</dt>
      <dd class="govuk-summary-list__value"> {{app.deploymentId}} </dd>
    </div>
    <div *ngIf="app.processDeployments" class="govuk-summary-list__row">
      <dt class="govuk-summary-list__key">Child deployment ids</dt>
      <dd *ngFor="let dep of app.processDeployments; let i = index" class="govuk-summary-list__value"> {{dep.id}} </dd>
    </div>
  </dl>

  <table *ngIf="app.resources" class="govuk-table">
    <caption class="govuk-heading-m govuk-table__caption">Resources included</caption>
    <thead class="govuk-table__head">
      <tr class="govuk-table__row">
        <th scope="col" class="govuk-table__header"></th>
        <th scope="col" class="govuk-table__header">Name</th>
        <th scope="col" class="govuk-table__header">Media type</th>
        <th scope="col" class="govuk-table__header">Type</th>
        <th scope="col" class="govuk-table__header">&nbsp;</th>
      </tr>
    </thead>
    <tbody class="govuk-table__body">
      <tr *ngFor="let resource of app.resources; let i = index" class="govuk-table__row">
        <td scope="row" class="govuk-table__header">{{i+1}}</td>
        <td class="govuk-table__cell">{{resource.id}}</td>
        <td class="govuk-table__cell">{{resource.mediaType}}</td>
        <td class="govuk-table__cell">{{resource.type}}</td>
        <td class="govuk-table__cell">
          <span *ngFor="let id of resource.contentIds">
            <button class="govuk-button" data-module="govuk-button" [routerLink]="['/proc-defs/proc-def-detail', id]">
              View details
            </button>
          </span>
        </td>
      </tr>
    </tbody>
  </table>

  <div class="govuk-button-group">
    <button class="govuk-button" data-module="govuk-button" [routerLink]="['/apps']">
      Back to list
    </button>
    <button class="govuk-button govuk-button--warning" data-module="govuk-button" (click)="onDelete(app)">
      Delete app
    </button>
  </div>
</div>
