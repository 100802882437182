import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Job } from '../job';
import { MessageService } from '../message.service';
import { ManagementService } from '../management.service';
import { QueryMetaData } from '../query-metadata';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-job-list',
  templateUrl: './job-list.component.html',
  styleUrls: ['./job-list.component.scss']
})
export class JobListComponent implements OnInit {

  active: boolean;
  jobs: Job[];
  selectedJob: Job;
  job: Job;
  query: QueryMetaData;

  constructor(private route: ActivatedRoute,
              private mgmtService: ManagementService,
              private messageService: MessageService) { }

  ngOnInit(): void {
    this.route.queryParamMap
      .subscribe((params) => {
        // tslint:disable-next-line: radix
        const start = params.get('start') === null ? 0 : parseInt(params.get('start'));
        // tslint:disable-next-line: radix
        const size = params.get('size') === null ? environment.pageSize : parseInt(params.get('size'));
        this.query = { start, size } as QueryMetaData;
        this.active = params.get('active') === null ? false : params.get('active') === 'active';
        if (this.active) {
          this.getJobs(this.query);
        } else {
          this.getDeadletterJobs(this.query);
        }
      }
    );
  }

  getDeadletterJobs(query: QueryMetaData): void {
    this.mgmtService.getDeadletterJobs(query)
        .subscribe(jobs => this.jobs = jobs);
  }

  getJobs(query: QueryMetaData): void {
    this.mgmtService.getJobs(query)
        .subscribe(jobs => this.jobs = jobs);
  }

}
