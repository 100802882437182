<form [formGroup]="setStarterForm">
  <fieldset class="govuk-fieldset govuk-!-margin-top-2">
    <legend class="govuk-fieldset__legend govuk-fieldset__legend--l">
      <h1 class="govuk-fieldset__heading">
        Specify who can start new instances of the app
      </h1>
    </legend>

    <div class="govuk-form-group">
      <label class="govuk-label" for="file-upload-1">
        User or Group
      </label>
        <input class="govuk-input govuk-input--width-20" id="starter" name="starter" required type="text"
               formControlName="starter" (change)="onChange($event)">
    </div>

    <button class="govuk-button" data-module="govuk-button"
            (click)="onSubmit()">
      Save and continue
    </button>
  </fieldset>
</form>
