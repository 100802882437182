<h1 class="govuk-heading-l">Digital service admin hub</h1>

<p class="govuk-body">Use this service to:</p>

<ul class="govuk-list govuk-list--bullet">
  <li>view available apps and the parts that make them up</li>
  <li>upload existing apps (.zip files) that you have previously built</li>
  <li>specify who can access the app at all or interact with specific parts</li>
</ul>

<p class="govuk-body">Apps collect together a number of resources that together represent a self-contained digital service that can be independently used and revised.</p>

<a href="#" role="button" draggable="false" class="govuk-button govuk-!-margin-top-2 govuk-!-margin-bottom-8 govuk-button--start"
   data-module="govuk-button" [routerLink]="['/apps']">
  Start now
  <svg class="govuk-button__start-icon" xmlns="http://www.w3.org/2000/svg" width="17.5" height="19" viewBox="0 0 33 40" aria-hidden="true" focusable="false">
    <path fill="currentColor" d="M0 0h13l20 20-20 20H0l20-20z" />
  </svg>
</a>

<!--aside class="app-related-items" role="complementary">
  <h2 class="govuk-heading-m">Before you start</h2>
  <nav role="navigation" aria-labelledby="subsection-title">
    <ul class="govuk-list govuk-!-font-size-16">
      <li>
        <a class="govuk-link" href="#"> Related link </a>
      </li>
      <li>
        <a class="govuk-link" href="#"> Related link </a>
      </li>
      <li>
        <a href="#" class="govuk-link govuk-!-font-weight-bold">
          More <span class="govuk-visually-hidden">in Subsection</span>
        </a>
      </li>
    </ul>
  </nav>
</aside-->
