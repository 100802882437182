import { Component, Input, OnInit } from '@angular/core';

import { PaginationService } from '../pagination.service';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent {

  @Input()
  active: string;

  constructor(public paginationService: PaginationService) { }
}
