import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms'; // <-- NgModel lives here
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MessagesComponent } from './messages/messages.component';
import { ProcDefComponent } from './proc-def-list/proc-def-list.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProcDefDetailComponent } from './proc-def-detail/proc-def-detail.component';
import { ProcInstListComponent } from './proc-inst-list/proc-inst-list.component';
import { ProcInstDetailComponent } from './proc-inst-detail/proc-inst-detail.component';
import { JobListComponent } from './job-list/job-list.component';
import { JobDetailComponent } from './job-detail/job-detail.component';
import { PaginationComponent } from './pagination/pagination.component';
import { AppListComponent } from './app-list/app-list.component';
import { AppDetailComponent } from './app-detail/app-detail.component';
import { AppUploadComponent } from './app-upload/app-upload.component';
import { ProcStarterComponent } from './proc-starter/proc-starter.component';
import { FriendlyDurationPipe } from './friendly-duration.pipe';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

@NgModule({
  declarations: [
    AppComponent,
    MessagesComponent,
    ProcDefComponent,
    DashboardComponent,
    ProcDefDetailComponent,
    ProcInstListComponent,
    ProcInstDetailComponent,
    JobListComponent,
    JobDetailComponent,
    PaginationComponent,
    AppListComponent,
    AppDetailComponent,
    AppUploadComponent,
    ProcStarterComponent,
    FriendlyDurationPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
