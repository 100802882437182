import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';

// import { DOMImplementationImpl, DOMParserImpl, XMLSerializerImpl } from 'xmldom-ts';
// import { install, xsltProcess, xmlParse } from 'xslt-ts';

import { IdentityLink } from './identity-link';
import { MessageService } from './message.service';
import { PaginationService } from './pagination.service';
import { ProcessDefinition } from './process-definition';
import { ProcessDefinitionResponse } from './process-definition-response';
import { ProcessResource } from './process-resource';
import { DiscoveryService } from './discovery-service';
import { ProfileService } from './profile.service';
import { QueryMetaData } from './query-metadata';
import { ProcessDeployment } from './process-deployment';
import { ProcessDeploymentResponse } from './process-deployment-response';

@Injectable({
  providedIn: 'root'
})
export class RepoService {

  XSLT_PROCESS_RENDERER_URL = 'https://modeler.knowprocess.com/xslt/bpmn2svg.xslt';
  private parser: DOMParser;
  private serializer: XMLSerializer;
  private transformer: XSLTProcessor;
  private xslt: string;

  constructor(
      private discoveryService: DiscoveryService,
      private profileService: ProfileService,
      private http: HttpClient,
      private messageService: MessageService,
      private paginationService: PaginationService) {

    this.parser = new DOMParser(),
    this.serializer = new XMLSerializer(),
    this.transformer = new XSLTProcessor(),
    this.getProcessRenderer()
        .subscribe(xslt => {
          this.xslt = xslt;
          var xsltDom = this.parser.parseFromString(xslt, 'text/xml');
          this.transformer.importStylesheet(xsltDom.documentElement);
        });
  }

  /** Log a RepoService message with the MessageService */
  private log(message: string) {
    this.messageService.set(`${message}`);
  }

  deleteProcessDeployment(processDeploymentId: string, cascade: boolean) {
    return this.http.delete( this.discoveryService.getDeleteProcessDeploymentUrl(processDeploymentId, cascade), {headers: this.getHttpHeaders()} )
      .pipe(
        catchError(this.handleError('deleteProcessDeployment', undefined))
      );
  }

  getChildProcessDeployments(parentId: string): Observable<ProcessDeployment[]> {
    this.log(`Showing details of ${parentId}`);
    return this.http.get<ProcessDeploymentResponse>( this.discoveryService.getProcessDeploymentsUrl()+'?parentDeploymentId='+parentId, {headers: this.getHttpHeaders()} )
      .pipe(
        map(x => x.data), /* flowable response includes metadata, just want data */
        catchError(this.handleError<ProcessDeployment[]>('getChildProcessDeployments', undefined))
      );
  }

  getProcessDefinitions(queryMeta: QueryMetaData, query?: object): Observable<ProcessDefinition[]> {
    if (query === undefined) query = { };
    console.info('  proc def query: ' + JSON.stringify(queryMeta));
    // tslint:disable-next-line: max-line-length
    return this.http.get<ProcessDefinitionResponse>( this.discoveryService.getProcessDefinitionsUrl(queryMeta, query), {headers: this.getHttpHeaders()} )
      .pipe(
        tap(x => this.log(`Found ${x.total} service${x.total > 1 ? 's' : ''} you can request`)),
        tap(x => this.paginationService.paginate('/proc-defs', { total: x.total, start: x.start, size: queryMeta.size} as QueryMetaData)),
        map(x => x.data), /* flowable response includes metadata, just want data */
        catchError(this.handleError<ProcessDefinition[]>('getProcessDefinitions', []))
      );
  }

  getProcessDefinition(procDefId: string): Observable<ProcessDefinition> {
    this.log(`Showing details of ${procDefId}`);
    return this.http.get<ProcessDefinition>( this.discoveryService.getProcessDefinitionUrl(procDefId), {headers: this.getHttpHeaders()} )
      .pipe(
        tap(x => this.log(`Found definition '${x.name == undefined ? x.id : x.name}'`)),
        tap(x => {
          this.getStarters(x.id)
              .subscribe(starters => x.starters = starters);
          this.getProcessResource(x.resource)
              .subscribe(resource => x.resourceContent = resource);
        }),
        catchError(this.handleError<ProcessDefinition>('getProcessDefinition', undefined))
      );
  }

  getProcessRenderer(): Observable<string> {
    return this.http.get( this.XSLT_PROCESS_RENDERER_URL, {headers: this.getHttpHeaders(), responseType: 'text'} )
      .pipe(
        catchError(this.handleError<string>('getProcessRenderer', null))
      );
  }

  getProcessResource(procDefResourceUrl: string): Observable<ProcessResource> {
    return this.http.get<ProcessResource>( procDefResourceUrl, {headers: this.getHttpHeaders()} )
      .pipe(
        tap(x => {
          this.getProcessResourceContent(x)
              .subscribe(content => x.content = content);
        }),
        catchError(this.handleError<ProcessResource>('getProcessResource', null))
      );
  }

  getProcessResourceContent(resource: ProcessResource): Observable<string> {
    return this.http.get( resource.contentUrl, {headers: this.getHttpHeaders(), responseType: 'text' } )
      .pipe(
        tap(bpmn => {
          resource.content = bpmn;
          resource.diagram = this.render(bpmn);
        }),
        catchError(this.handleError<string>('getProcessResourceContent', null))
      );
  }

  getStarters(procDefId: string): Observable<IdentityLink[]> {
    return this.http.get<IdentityLink[]>( this.discoveryService.getProcessStartersUrl(procDefId), {headers: this.getHttpHeaders()} )
      .pipe(
        catchError(this.handleError<IdentityLink[]>('getStarters', []))
      );
  }finition

  render(bpmn: string): string {
    this.transformer.clearParameters();
    // this.transformer.setParameter(null, 'diagramId', 'BPMNDiagram_1');
    this.transformer.setParameter(null, 'showBounds', false);
    this.transformer.setParameter(null, 'showIssue', true);
    this.transformer.setParameter(null, 'debug', true);
    try {
      var bpmnDom = this.parser.parseFromString(bpmn, 'text/xml');
      var result = this.transformer.transformToDocument(bpmnDom);
      if (result) {
        return this.serializer.serializeToString(result.firstElementChild);
      }
    } catch (e) {
      this.log('Unable to render image of BPMN');
    }
  }

  setStarter(procDefId: string, payload: IdentityLink): Observable<IdentityLink> {
    return this.http.post<IdentityLink>( this.discoveryService.getProcessStartersUrl(procDefId), payload, {headers: this.getHttpHeaders()} )
      .pipe(
        catchError(this.handleError<IdentityLink>('setStarter', payload))
      );
  }

  private getHttpHeaders(): HttpHeaders {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('Content-Type', 'application/json');
    return headers.append('Authorization', 'Basic ' + btoa(this.profileService.getUsername() + ':' + this.profileService.getToken()));
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
