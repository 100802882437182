import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';

import { AppService } from '../app.service';
import { MessageService } from '../message.service';

@Component({
  selector: 'app-app-upload',
  templateUrl: './app-upload.component.html',
  styleUrls: ['./app-upload.component.scss']
})
export class AppUploadComponent implements OnInit {

//  const app: AppDefinition = new AppDefinition();
  uploadForm: FormGroup;

  constructor(private appService: AppService,
              private formBuilder: FormBuilder,
              private messageService: MessageService,
              private router: Router) {
    this.uploadForm = this.formBuilder.group({
      file: ''
    });
  }

  ngOnInit(): void {
    this.messageService.clear();
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.uploadForm.get('file').setValue(file);
    }
  }

  onSubmit(): void {
    this.messageService.set(`Deploying app...`);
    const formData = new FormData();
    formData.append('file', this.uploadForm.get('file').value);


    this.appService
      .upload(formData)
      .subscribe(app => {
        this.router.navigate(['/apps']);
      });
  }

}
