import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';

import { HistoricActivityInstance } from './historic-activity-instance';
import { HistoricActivityResponse } from './historic-activity-response';
import { MessageService } from './message.service';
import { PaginationService } from './pagination.service';
import { ProcessInstance } from './process-instance';
import { ProcessInstanceResponse } from './process-instance-response';
import { DiscoveryService } from './discovery-service';
import { ProfileService } from './profile.service';
import { QueryMetaData } from './query-metadata';
import { Variable } from './variable';
import { HistoricVariableInstanceResponse } from './historic-variable-instance-response';
import { HistoricVariableInstance } from './historic-variable-instance';

@Injectable({
  providedIn: 'root'
})
export class HistoryService {

  constructor(
      private discoveryService: DiscoveryService,
      private profileService: ProfileService,
      private http: HttpClient,
      private paginationService: PaginationService,
      private messageService: MessageService) { }

  /** Log a RuntimeService message with the MessageService */
  private log(message: string) {
    this.messageService.set(`${message}`);
  }

  deleteHistoricProcessInstance(procInstId: string): Observable<ProcessInstance> {
    this.log(`Deleting ${procInstId}`);
    return this.http.delete<ProcessInstance>( this.discoveryService.getHistoricProcessInstanceUrl(procInstId), {headers: this.getHeaders()} )
      .pipe(
        tap(_ => this.log(`Deleted instance '${procInstId}'`)),
        catchError(this.handleError<ProcessInstance>('deleteHistoricProcessInstance', undefined))
      );
  }

  getHistoricProcessInstances(query: QueryMetaData): Observable<ProcessInstance[]> {
    // tslint:disable-next-line: max-line-length
    return this.http.get<ProcessInstanceResponse>( this.discoveryService.getHistoricProcessInstancesUrl(query), { headers: this.getHeaders() } )
      .pipe(
        tap(x => this.log(`Found ${x.total} instances`)),
        tap(x => this.paginationService.paginate('/proc-insts', { total: x.total, start: x.start, size: query.size} as QueryMetaData)),
        map(x => x.data), /* flowable response includes metadata, just want data */
        catchError(this.handleError<ProcessInstance[]>('getHistoricProcessInstances', []))
      );
  }

  getHistoricProcessInstance(procInstId: string): Observable<ProcessInstance> {
    // tslint:disable-next-line: max-line-length
    return this.http.get<ProcessInstance>( this.discoveryService.getHistoricProcessInstanceUrl(procInstId), {headers: this.getHeaders() } )
      .pipe(
        tap(x => this.log(`Found instance ${x.id}`)),
        catchError(this.handleError<ProcessInstance>('getHistoricProcessInstance', undefined))
      );
  }

  getHistoricActivityInstances(procInstId: string): Observable<HistoricActivityInstance[]> {
    // tslint:disable-next-line: max-line-length
    return this.http.get<HistoricActivityResponse>( this.discoveryService.getHistoricActivityInstancesUrl(procInstId), {headers: this.getHeaders() } )
      .pipe(
        map(x => x.data), /* flowable response includes metadata, just want data */
        catchError(this.handleError<ProcessInstance[]>('getHistoricActivityInstances', []))
      );
  }

  reducer(accumulator: Variable[], currentValue: HistoricVariableInstance): Variable[] {
    accumulator.push(currentValue.variable);
    return accumulator;
  }

  getHistoricProcessInstanceVariables(procInstId: string, dataSize: number): Observable<Variable[]> {
    this.log(`Showing details of ${procInstId}`);
    const url = this.discoveryService.getHistoricProcessInstanceVarablesUrl(procInstId, dataSize);
    return this.http.get<HistoricVariableInstanceResponse>( url, {headers: this.getHeaders()} )
      .pipe(
        map(x => x.data.reduce( this.reducer, [] )),
        catchError(this.handleError<Variable[]>('getHistoricProcessInstanceVariables', []))
      );
  }

  private getHeaders(): HttpHeaders {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', 'Basic ' + btoa(this.profileService.getUsername() + ':' + this.profileService.getToken()));
    return headers;
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
