<button class="govuk-button" data-module="govuk-button" [routerLink]="['/app-upload']">
  Upload a new app
</button>
<table class="govuk-table">
  <!--caption class="govuk-heading-l govuk-table__caption">Process Definitions</caption-->
  <thead class="govuk-table__head">
    <tr class="govuk-table__row">
      <th scope="col" class="govuk-table__header"></th>
      <th scope="col" class="govuk-table__header">Name</th>
      <th scope="col" class="govuk-table__header">Description</th>
      <!--th scope="col" class="govuk-table__header">Category</th-->
      <th scope="col" class="govuk-table__header">&nbsp;</th>
    </tr>
  </thead>
  <tbody class="govuk-table__body">
    <tr *ngFor="let app of apps; let i = index" class="govuk-table__row"
        [class.selected]="app === selectedApp">
      <td scope="row" class="govuk-table__header"><a [routerLink]="['/apps/app-detail', app.id]">{{i+1}}</a></td>
      <td class="govuk-table__cell">{{app.name}}</td>
      <td class="govuk-table__cell">{{app.description}}</td>
      <!--td class="govuk-table__cell">{{app.category}}</td-->
      <td class="govuk-table__cell">
        <button class="govuk-button" data-module="govuk-button" [routerLink]="['/apps/app-detail', app.id]">
          View details
        </button>
      </td>
    </tr>
  </tbody>
</table>
<app-pagination><app-pagination>
