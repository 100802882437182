import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';

import { MessageService } from './message.service';
import { PaginationService } from './pagination.service';
import { ProcessInstance } from './process-instance';
import { ProcessInstanceResponse } from './process-instance-response';
import { DiscoveryService } from './discovery-service';
import { ProfileService } from './profile.service';
import {QueryMetaData } from './query-metadata';
import { Variable } from './variable';

@Injectable({
  providedIn: 'root'
})
export class RuntimeService {

  constructor(
      private discoveryService: DiscoveryService,
      private profileService: ProfileService,
      private http: HttpClient,
      private messageService: MessageService,
      private paginationService: PaginationService) { }

  /** Log a RuntimeService message with the MessageService */
  private log(message: string) {
    this.messageService.set(`${message}`);
  }

  deleteProcessInstance(procInstId: string): Observable<ProcessInstance> {
    this.log(`Deleting ${procInstId}`);
    return this.http.delete<ProcessInstance>( this.discoveryService.getProcessInstanceUrl(procInstId), {headers: this.getHttpHeaders()} )
      .pipe(
        tap(_ => this.log(`Deleted instance '${procInstId}'`)),
        catchError(this.handleError<ProcessInstance>('deleteProcessInstance', undefined))
      );
  }

  queryProcessInstances(query): Observable<ProcessInstance[]> {
    return this.http.post<ProcessInstanceResponse>( this.discoveryService.getQueryProcessInstancesUrl(), query, {headers: this.getHttpHeaders()} )
      .pipe(
        tap(x => this.log(`Found ${x.total} instances`)),
        map(x => x.data), /* flowable response includes metadata, just want data */
        catchError(this.handleError<ProcessInstance[]>('queryProcessInstances', []))
      );
  }

  getProcessInstances(query: QueryMetaData): Observable<ProcessInstance[]> {
    return this.http.get<ProcessInstanceResponse>( this.discoveryService.getProcessInstancesUrl(query), {headers: this.getHttpHeaders()} )
      .pipe(
        tap(x => this.log(`Found ${x.total} instances`)),
        tap(x => this.paginationService.paginate('/proc-insts', { total: x.total, start: x.start, size: query.size} as QueryMetaData)),
        map(x => x.data), /* flowable response includes metadata, just want data */
        catchError(this.handleError<ProcessInstance[]>('getProcessInstances', []))
      );
  }

  getProcessInstance(procInstId: string, params?: string): Observable<ProcessInstance> {
    this.log(`Showing details of ${procInstId}`);
    const url = this.discoveryService.getProcessInstanceUrl(procInstId) + '?' + params;
    return this.http.get<ProcessInstance>( url, {headers: this.getHttpHeaders()} )
      .pipe(
        tap(x => this.log(`Found instance '${x.businessKey == undefined ? (x.name == undefined ? x.id : x.name) : x.businessKey}'`)),
        catchError(this.handleError<ProcessInstance>('getProcessInstance', undefined))
      );
  }

  getProcessInstanceVariables(procInstId: string, dataSize: number): Observable<Variable[]> {
    this.log(`Showing details of ${procInstId}`);
    const url = this.discoveryService.getProcessInstanceVarablesUrl(procInstId, dataSize);
    return this.http.get<Variable[]>( url, {headers: this.getHttpHeaders()} )
      .pipe(
        catchError(this.handleError<Variable[]>('getProcessInstanceVariables', []))
      );
  }

  private getHttpHeaders(): HttpHeaders {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('Content-Type', 'application/json');
    return headers.append('Authorization', 'Basic ' + btoa(this.profileService.getUsername() + ':' + this.profileService.getToken()));
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
