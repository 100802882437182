import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import * as Keycloak from 'keycloak-js';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

if (environment.contentLinks['favicon'] !== undefined) {
 document.querySelector('link[rel="shortcut icon"]').setAttribute('href', environment.contentLinks.favicon);
}
console.warn('Using process server: ' + environment.server.url);
// keycloak init options
const initOptions = {
  url: environment.authServer.url, realm: environment.authServer.realm, clientId: environment.authServer.clientId
};

const keycloak = Keycloak(initOptions);

keycloak.init({ onLoad: 'login-required' }).success((auth) => {

  if (!auth) {
    window.location.reload();
  } else {
    console.log('Authenticated');
  }

  // bootstrap after authentication is successful.
  platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));

  localStorage.setItem('ang-token', keycloak.token);
  localStorage.setItem('ang-refresh-token', keycloak.refreshToken);

  keycloak.loadUserProfile().success((profile) => {
    localStorage.setItem('ang-profile', JSON.stringify(profile));
  }).error(() => {
    alert('Failed to load user profile');
  });

  setTimeout(() => {
    keycloak.updateToken(70).success((refreshed) => {
      if (refreshed) {
        // tslint:disable-next-line: no-console
        console.debug('Token refreshed' + refreshed);
      } else {
        console.warn('Token not refreshed, valid for '
          + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
      }
    }).error(() => {
      console.error('Failed to refresh token');
    });


  }, 60000);

}).error(() => {
  console.error('Authenticated Failed');
});
