import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'friendlyDuration'
})
export class FriendlyDurationPipe implements PipeTransform {

  transform(duration: number): string {
    let secs:number = duration / 1000;
    let mins = secs / 60;
    let hours = mins / 60;
    let days = hours / 24;
    let weeks = days / 7;
    let years = days / 365;
    if (secs < 1) {
      return 'less than a second';
    } else if (secs < 2) {
      return 'about a second';
    } else if (mins < 1) {
      return 'less than a minute';
    } else if (mins < 2) {
      return 'about a minute';
    } else if (mins < 60) {
      return 'about '+Math.floor(mins) + ' minutes';
    } else if (hours < 2) {
      return 'about an hour';
    } else if (days < 1) {
      return 'about '+Math.floor(hours) + ' hours';
    } else if (days < 2) {
      return 'about a day';
    } else if (years > 1) {
      return 'about ' + Math.floor(years) + ' years';
    } else if (weeks > 2) {
      return 'about ' + Math.floor(weeks) + ' weeks';
    } else {
      return 'about ' + Math.floor(days) + ' days';
    }
  };
}
