<div class="govuk-form-group">
  <fieldset class="govuk-fieldset" aria-describedby="jobType-hint">
    <div class="govuk-radios govuk-radios--inline">
      <div class="govuk-radios__item">
        <input class="govuk-radios__input" id="jobType" name="jobType" type="radio" value="active"
               (click)="getJobs(query)">
        <label class="govuk-label govuk-radios__label" for="jobType"> Active </label>
      </div>
      <div class="govuk-radios__item">
        <input class="govuk-radios__input" id="jobType-2" checked name="jobType" type="radio" value="deadletter"
               (click)="getDeadletterJobs(query)">
        <label class="govuk-label govuk-radios__label" for="jobType-2"> In error </label>
      </div>
    </div>
  </fieldset>
</div>

<table class="govuk-table">
  <thead class="govuk-table__head">
    <tr class="govuk-table__row">
      <th scope="col" class="govuk-table__header"></th>
      <th scope="col" class="govuk-table__header">Process definition</th>
      <th scope="col" class="govuk-table__header">Process instance</th>
      <th scope="col" class="govuk-table__header">Execution</th>
      <th scope="col" class="govuk-table__header govuk-width-datetime">Due</th>
      <th scope="col" class="govuk-table__header">Retries</th>
      <th scope="col" class="govuk-table__header">Exception</th>
    </tr>
  </thead>
  <tbody class="govuk-table__body">
    <tr *ngFor="let job of jobs; let i = index" class="govuk-table__row"
        [class.selected]="job === selectedJob">
      <td scope="row" class="govuk-table__header"><a [routerLink]="['/jobs/job-detail', job.id]">{{i+1}}</a></td>
      <!--td scope="row" class="govuk-table__header">{{job.id}}</td-->
      <td class="govuk-table__cell" title="{{job.processDefinitionId}}"><a [routerLink]="['/proc-defs/proc-def-detail', job.processDefinitionId]">{{job.processDefinitionId | slice:0:24}}...</a></td>
      <td class="govuk-table__cell" title="{{job.processInstanceId}}"><a [routerLink]="['/proc-insts/proc-inst-detail', job.processInstanceId]">{{job.processInstanceId | slice:0:12}}...</a></td>
      <td class="govuk-table__cell" title="{{job.executionId}}">{{job.executionId | slice:0:12}}...</td>
      <td class="govuk-table__cell">{{job.dueDate | date:"dd MMM yy HH:mm"}}</td>
      <td class="govuk-table__cell">{{job.retries}}</td>
      <td class="govuk-table__cell">{{job.exceptionMessage}}</td>
    </tr>
  </tbody>
</table>
<app-pagination></app-pagination>
