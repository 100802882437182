import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { environment } from '../../environments/environment';

import { HistoryService } from '../history.service';
import { MessageService } from '../message.service';
import { ProcessInstance } from '../process-instance';
import { QueryMetaData} from '../query-metadata';
import { RuntimeService } from '../runtime.service';

@Component({
  selector: 'app-proc-inst-list',
  templateUrl: './proc-inst-list.component.html',
  styleUrls: ['./proc-inst-list.component.scss']
})
export class ProcInstListComponent implements OnInit {

  procInsts: ProcessInstance[];
  selectedProcInst: ProcessInstance;
  procInst: ProcessInstance;
  query: QueryMetaData;
  active: boolean;

  constructor(private route: ActivatedRoute,
              private runtimeService: RuntimeService,
              private historyService: HistoryService,
              private messageService: MessageService) { }

  ngOnInit(): void {
    this.route.queryParamMap
      .subscribe((params) => {
        // tslint:disable-next-line: radix
        const start = params.get('start') === null ? 0 : parseInt(params.get('start'));
        // tslint:disable-next-line: radix
        const size = params.get('size') === null ? environment.pageSize : parseInt(params.get('size'));
        this.query = { start, size } as QueryMetaData;
        this.active = params.get('active') === null ? true: params.get('active') === 'active';
        if (this.active) {
          this.getProcessInstances(this.query);
        } else {
          this.getHistoricProcessInstances(this.query);
        }
      }
    );
  }

  getProcessInstances(query: QueryMetaData): void {
    this.runtimeService.getProcessInstances(query)
        .subscribe(procInsts => this.procInsts = procInsts);
  }

  getHistoricProcessInstances(query: QueryMetaData): void {
    this.historyService.getHistoricProcessInstances(query)
        .subscribe(procInsts => this.procInsts = procInsts);
  }

}
