import { Injectable } from '@angular/core';

import { QueryMetaData } from './query-metadata';

@Injectable({
  providedIn: 'root'
})
export class PaginationService {
  active: boolean = true;
  total: number;
  start: number;
  size: number;
  selectedPage: number = 0;
  routerLink: string;

  constructor() { }

  public pages(): number[] {
    const pageCount = Math.ceil(this.total / this.size);
    const pages = [];
    for (let i = 0 ; i < pageCount ;  i++) {
      pages.push(i);
    }
    return pages;
  }

  isShown(i: number) {
    switch (i) {
      case 0:                      // first
      case 1:                      // second
      case this.selectedPage -1:   // previous
      case this.selectedPage:      // current
      case this.selectedPage +1:   // next
      case this.pages().length -2: // penultimate
      case this.pages().length -1: // last
        return true;
      default:
        return false;
    }
  }

  buttonText(i: number) {
    switch (i) {
      case this.selectedPage:      // current
        return 'page '+(i+1);
      case this.selectedPage -1:   // previous
        return '&lsaquo; page '+(i+1);
      case this.selectedPage +1:   // next
        return 'page '+(i+1)+' &rsaquo;';
      case 0:                      // first
        return '&laquo; page '+(i+1);
      case 1:                      // second
        return '&laquo; page '+(i+1);
      case this.pages().length -2: // penultimate
        return 'page '+(i+1)+' &raquo;';
      case this.pages().length -1: // last
        return 'page '+(i+1)+' &raquo;';
    }
  }

  paginate(link: string, query: QueryMetaData) {
    this.routerLink = link;
    this.start = query.start;
    this.size = query.size;
    this.total = query.total;
    this.selectedPage = Math.floor(this.start / this.size)
  }

}
